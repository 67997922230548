import { Injectable, OnDestroy } from '@angular/core';
import { HttpService } from '@shared/services/http/http.service';
import { catchError, Observable, Subscription, throwError } from 'rxjs';
import { Role } from '@shared/enums/Role';
import { ResponseHandlerService } from '@shared/services/response-handler/response-handler.service';
import { translate, TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  translocoSub: Subscription;

  // URL to the GET roles endpoint
  private readonly rolesEndpointURL: string = 'roles';

  constructor(
    private httpService: HttpService,
    private responseHandlerService: ResponseHandlerService,
    private translocoService: TranslocoService
  ) {
    this.translocoSub = this.translocoService.load(this.translocoService.getActiveLang()).subscribe();
  }

  ngOnDestroy(): void {
    if (this.translocoSub) this.translocoSub.unsubscribe();
  }

  /**
   * Retrieves the roles for the current user.
   * @returns An observable of an array of roles.
   */
  getRoles(): Observable<Role[]> {
    return this.httpService
      .request(this.rolesEndpointURL)
      .get()
      .pipe(
        catchError((err) => {
          console.error(err);
          this.responseHandlerService.handleError(translate('snacks.roles-endpoint-failed'));
          return throwError(() => err);
        })
      );
  }
}
